import { Button, Card, Skeleton, Statistic, Timeline } from "antd";
import { useContext, useEffect, useState } from "react";
import apiCaller from "utils/apiCaller";
import { EditFilled } from "@ant-design/icons";
import { AuthContext } from "context/AuthProvider";
import ReactLoading from "react-loading";
import { useActive } from "utils/useActiveHook";

interface Props {
  workflowId: string;
}

interface ActiveSigner {
  email?: string;
  url?: string;
}

interface Signer {
  email: string;
  hasSigned: boolean;
  role: string;
  name: string;
}

const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? 0 + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

const formatDate = (date: Date) => {
  return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
    .getDate()
    .toString()
    .padStart(2, "0")}/${date
    .getFullYear()
    .toString()
    .padStart(4, "0")} ${formatAMPM(date)} EST`;
};

const OutForSignatures = ({ workflowId }: Props) => {
  const { activeUser } = useContext(AuthContext);
  const [signers, setSigners] = useState<Signer[]>([]);
  const [coordinator, setCoordinator] = useState<String>();
  const [activeSigner, setActiveSigner] = useState<ActiveSigner>();
  const [lastReminderSent, setLastReminderSent] = useState("N/A");
  const [isReminderButtonEnabled, setIsReminderButtonEnabled] = useState(true);

  const isActive = useActive(10000);

  const [loading, setLoading] = useState(true);
  const getWorkflowData = async () => {
    const workflowResp = await apiCaller.get("/api/workflow", {
      params: { id: workflowId },
      timeout: 30000,
    });
    const workflow = workflowResp?.data;
    return workflow;
  };
// Function to find name by email for external user
const findNameByEmail = (data: any, email: string): string => {
  // console.log("findNameByEmail",data)
  // Loop through the keys of the data object
  for (let i = 1; i <= 10; i++) { // Adjust the loop count based on the number of additional members you expect
    const memberEmailKey = `additionalMember${i}`;
    const memberNameKey = `additionalMember${i}Name`;

    // Check if the member email exists and matches the provided email
    if (data[memberEmailKey] === email) {
      return data[memberNameKey]; // Return the corresponding name
    }
  }

  return ""; 
};
  useEffect(() => {
    async function getActiveAgreement() {
      //setLoading(true);
      const res = await apiCaller.get("/api/active-agreement", {
        params: { workflowId },
        timeout: 70000,
      });
      if (res.data.lastReminderSent) {
        setLastReminderSent(
          formatDate(new Date(res.data.lastReminderSent._seconds * 1000))
        );
      }
      setCoordinator(res.data.coordinator.toLowerCase());

      let signerData = res.data.signers;
      let agreementId = res.data.agreementId;
      let externalUserName="External User";
      for (let i = 0; i < signerData.length; i++) {
        const userRes = await apiCaller.get("/api/user", {
          params: {
            email: signerData[i].email,
          },
          timeout: 70000,
        });
        if (!userRes?.data){
            const workflowData=await getWorkflowData()
            externalUserName=findNameByEmail(workflowData?.formData?.formValues,signerData[i].email)
        }
        signerData[i].name = userRes?.data?.name ? userRes.data.name : externalUserName;
      }

      const adobeAgreementResp = await apiCaller.get("/api/adobe-agreement-status", {
        params: {
          agreementId: agreementId
        },
        timeout: 70000,
      });

      if (adobeAgreementResp.data.status === "OUT_FOR_SIGNATURE") {
        const adobeSigningUrlRes = await apiCaller.get("/api/signing-urls", {
          params: {
            agreementId: agreementId,
          },
          timeout: 70000,
        });
        setLoading(false);
  
        let signingUrlRes = adobeSigningUrlRes?.data?.signers;
        for (let i = 0; i < signingUrlRes.length; i++) {
          if (
            signingUrlRes[i].email.toLowerCase() ===
            activeUser.email.toLowerCase()
          ) {
            setActiveSigner(signingUrlRes[i]);
          }
        }
        setSigners(signerData);
      } else {
        const allSigned = signerData.every((item: any) => item.hasSigned === true)
        if (allSigned) {
          setSigners(signerData);
        }
        setLoading(false);
      }
    }
    if (isActive) {
      try {
        const interval = setInterval(
          async () => await getActiveAgreement(),
          10000
        );
        return () => clearInterval(interval);
      } catch (err) {
        console.log(err);
      }
    }

    const differenceInMs = Date.now() - new Date(lastReminderSent).getTime();
    const differenceInHours = differenceInMs / (1000 * 60 * 60);

    // Enable the "Send Reminder" button if the difference is more than 24 hours, otherwise disable it
    setIsReminderButtonEnabled(lastReminderSent === "N/A" || differenceInHours > 24);
  }, [activeUser.email, isActive, workflowId, lastReminderSent]);

  const sendReminder = async () => {
    await apiCaller.post("/api/send-adobe-reminder-mail", { workflowId });
    setLastReminderSent(formatDate(new Date()));
  };

  if (loading) {
    return (
      <Card bordered={false}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: 24 }}>Loading, please wait...</span>
          <ReactLoading type="spokes" color="#990000" />
        </div>
        <Skeleton active />
      </Card>
    );
  }

  return (
    <div>
      <Timeline>
        {signers.map((signer, index) => (
          <Timeline.Item color={signer.hasSigned ? "green" : "red"} key={index}>
            {signer.name} [{signer.role}]{" "}
            {signer.hasSigned ? "has signed" : "has not signed"}
            {activeUser.email.toLowerCase() === signer.email.toLowerCase() &&
            activeUser.email.toLowerCase() === activeSigner?.email ? (
              <div>
                {" "}
                <br />
                <Button
                  type="primary"
                  shape="round"
                  icon={<EditFilled />}
                  href={activeSigner?.url}
                >
                  Adobe Sign
                </Button>
              </div>
            ) : null}
          </Timeline.Item>
        ))}
      </Timeline>

      {activeUser.email.toLowerCase() === coordinator ? (
        <div>
          <Statistic title="Last Reminder Sent" value={lastReminderSent} />
          <Button onClick={sendReminder} disabled={!isReminderButtonEnabled}>Send Reminder</Button>
        </div>
      ) : null}
    </div>
  );
};

export default OutForSignatures;
