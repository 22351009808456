import React, { useContext, useState, useEffect } from 'react';

import {
    Card,
    Divider,
} from "antd";

interface Props {
    instructions: string;
}

function WorkflowInstructions({ instructions }: Props){

    return (
        <Card title="Instructions:" style={{marginBottom: '20px'}}>
            <div dangerouslySetInnerHTML={{ __html: instructions }} />
        </Card>
    );
}

export default WorkflowInstructions;